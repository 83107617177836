@import '../colors';

// disable ripples on all buttons
.mat-button {
  text-align: center;
  border-radius: 0 !important;
  transition: background-color 0.15s ease-in-out;

  > .mat-button-ripple {
    display: none;
  }
}

// flat button, turn off all borders
.mat-button.mdc-button--unelevated,
.mat-button.mdc-button--unelevated[disabled] {
  border: none;
}

// turn off overlay on flat buttons
.mat-button.mdc-button--unelevated {
  min-width: 164px;
  min-height: 48px;
  border-width: 2px;
  > .mat-button-focus-overlay {
    display: none;
  }
}

// button stroked primary hover
.mat-button.mdc-button--unelevated.mat-primary:not([disabled]):hover {
  background-color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);

  > .mat-button-focus-overlay {
    display: none;
  }
}

.mat-button.mat-stroked-button {
  border-width: 2px;
  min-width: 164px;
  min-height: 48px;

  > .mat-button-focus-overlay {
    display: none;
  }
}

.mat-button.mat-stroked-button.mat-primary {
  border-color: mat.get-color-from-palette($mat-ef-primary, 500);
  &:disabled {
    border-color: mat.get-color-from-palette($mat-ef-grey, 500);
  }
}

// stroked button hover color when not disabled
.mat-button.mat-stroked-button.mat-primary:not([disabled]):hover {
  border-color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);
  color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);
}

@media #{$tablet-media} {
  @import '../colors';

  // disable ripples on all buttons
  .mat-button {
    text-align: center;
    border-radius: 0 !important;
    transition: background-color 0.15s ease-in-out;

    > .mat-button-ripple {
      display: none;
    }
  }

  // flat button, turn off all borders
  .mat-button.mdc-button--unelevated,
  .mat-button.mdc-button--unelevated[disabled] {
    border: none;
  }

  // turn off overlay on flat buttons
  .mat-button.mdc-button--unelevated {
    min-width: 164px;
    min-height: 48px;
    border-width: 2px;
    > .mat-button-focus-overlay {
      display: none;
    }
  }

  // button stroked primary hover
  .mat-button.mdc-button--unelevated.mat-primary:not([disabled]):hover {
    background-color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);

    > .mat-button-focus-overlay {
      display: none;
    }
  }

  .mat-button.mat-stroked-button {
    border-width: 2px;
    min-width: 164px;
    min-height: 48px;

    > .mat-button-focus-overlay {
      display: none;
    }
  }

  .mat-button.mat-stroked-button.mat-primary {
    border-color: mat.get-color-from-palette($mat-ef-primary, 500);
  }

  // stroked button hover color when not disabled
  .mat-button.mat-stroked-button.mat-primary:not([disabled]):hover {
    border-color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);
    color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);
  }
}

@media #{$mobile-media} {
  @import '../colors';

  // disable ripples on all buttons
  .mat-button {
    text-align: center;
    border-radius: 0 !important;
    transition: background-color 0.15s ease-in-out;

    > .mat-button-ripple {
      display: none;
    }
  }

  // flat button, turn off all borders
  .mat-button.mdc-button--unelevated,
  .mat-button.mdc-button--unelevated[disabled] {
    border: none;
  }

  // turn off overlay on flat buttons
  .mat-button.mdc-button--unelevated {
    min-width: 100%;
    min-height: 48px;
    border-width: 2px;
    margin-bottom: 10px;
    > .mat-button-focus-overlay {
      display: none;
    }
  }

  // button stroked primary hover
  .mat-button.mdc-button--unelevated.mat-primary:not([disabled]):hover {
    background-color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);

    > .mat-button-focus-overlay {
      display: none;
    }
  }

  .mat-button.mat-stroked-button {
    border-width: 2px;
    min-width: 100%;
    min-height: 48px;
    margin-bottom: 10px;

    > .mat-button-focus-overlay {
      display: none;
    }
  }

  .mat-button.mat-stroked-button.mat-primary {
    border-color: mat.get-color-from-palette($mat-ef-primary, 500);
  }

  // stroked button hover color when not disabled
  .mat-button.mat-stroked-button.mat-primary:not([disabled]):hover {
    border-color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);
    color: mat.get-color-from-palette($mat-ef-indigo-hover, 500);
  }
}
