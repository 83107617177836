@use '@angular/material' as mat;
@import '../colors';

.ef-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)}!important;
  --mat-tab-header-active-ripple-color: transparent!important;
  --mat-tab-header-inactive-ripple-color: transparent!important;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.5)!important;
  --mat-tab-header-active-label-text-color: rgba(0, 0, 0, 0.9)!important;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.5)!important;
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.5)!important;
  --mat-tab-header-active-focus-label-text-color: rgba(0, 0, 0, 0.9)!important;
  --mat-tab-header-active-hover-label-text-color: rgba(0, 0, 0, 0.9)!important;
  --mat-tab-header-active-focus-indicator-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)}!important;
  --mat-tab-header-active-hover-indicator-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)}!important;

  .mat-mdc-tab-links {
    border-bottom: 1px solid #{mat.get-color-from-palette($mat-ef-grey, 300)};
  }
}

.mat-tab-group_hide-header .mat-mdc-tab-header {
  display: none;
}
