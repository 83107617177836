@use '@angular/material' as mat;
@import './media';

@font-face {
  font-family: 'Canela Black';
  src: url('../fonts/CanelaBlack/Canela-Black-Web.woff2') format('woff2'),
    url('../fonts/CanelaBlack/Canela-Black-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'EF Sans';
  src: url('../fonts/EFSans/EFSansW01-Black.woff2') format('woff2'),
    url('../fonts/EFSans/EFSansW01-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'EF Sans';
  src: url('../fonts/EFSans/EFSansW01-Bold.woff2') format('woff2'),
    url('../fonts/EFSans/EFSansW01-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'EF Sans';
  src: url('../fonts/EFSans/EFSansW01-Semibold.woff2') format('woff2'),
    url('../fonts/EFSans/EFSansW01-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'EF Sans';
  src: url('../fonts/EFSans/EFSansW01-Regular.woff2') format('woff2'),
    url('../fonts/EFSans/EFSansW01-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'EF Sans';
  src: url('../fonts/EFSans/EFSansW01-Light.woff2') format('woff2'),
    url('../fonts/EFSans/EFSansW01-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'EF Sans Black';
  src: url('../fonts/EFSans/EFSansW01-Black.woff2') format('woff2'),
    url('../fonts/EFSans/EFSansW01-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'text-security-disc';
  src: url('../fonts/TextSecurity/text-security-disc.woff2') format('woff2'),
    url('../fonts/TextSecurity/text-security-disc.woff') format('woff');
}

.ef-small-heading-black-1 {
  font-family: 'EF Sans Black', Arial, sans-serif;
  font-size: 21px;
  font-weight: 900;
}

.ef-headline-1 {
  font-family: 'Canela Black', Arial, sans-serif;
  font-size: 61px;
  font-weight: 900;
}

.ef-headline-2 {
  font-family: 'Canela Black', Arial, sans-serif;
  font-size: 49px;
  font-weight: 900;
}

.ef-headline-3 {
  font-family: 'Canela Black', Arial, sans-serif;
  font-size: 39px;
  font-weight: 900;
}

.ef-headline-4 {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 31px;
  font-weight: 700;
}

.ef-headline-5 {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 25px;
  font-weight: 700;
}

.ef-headline-6 {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.ef-headline-7 {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.ef-lead {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.ef-lead-bold {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 23px;
  font-weight: 700;
}

.ef-paragraph {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.ef-paragraph-medium {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.ef-small-paragraph {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
}

@media #{$mobile-media} {
  .ef-headline-1 {
    font-family: 'Canela Black', Arial, sans-serif;
    font-size: 41px;
    font-weight: 900;
  }

  .ef-headline-2 {
    font-family: 'Canela Black', Arial, sans-serif;
    font-size: 36px;
    font-weight: 900;
  }

  .ef-headline-3 {
    font-family: 'Canela Black', Arial, sans-serif;
    font-size: 32px;
    font-weight: 900;
  }

  .ef-headline-4 {
    font-family: 'EF Sans', Arial, sans-serif;
    font-size: 35px;
    font-weight: 700;
  }

  .ef-headline-5 {
    font-family: 'EF Sans', Arial, sans-serif;
    font-size: 28px;
    font-weight: 700;
  }

  .ef-headline-6 {
    font-family: 'EF Sans', Arial, sans-serif;
    font-size: 23px;
    font-weight: 700;
  }

  .ef-headline-7 {
    font-family: 'EF Sans', Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
  }

  .ef-lead {
    font-family: 'EF Sans', Arial, sans-serif;
    font-size: 23px;
    font-weight: 500;
  }

  .ef-lead-bold {
    font-family: 'EF Sans', Arial, sans-serif;
    font-size: 23px;
    font-weight: 700;
  }

  .ef-paragraph {
    font-family: 'EF Sans', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
  }

  .ef-small-paragraph {
    font-family: 'EF Sans', Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$ef-typography: mat.define-legacy-typography-config(
  $font-family: 'EF Sans',
  $display-4:
    mat.define-typography-level(
      112px,
      112px,
      900,
      'Canela Black',
      $letter-spacing: -0.05em
    ),
  $display-3:
    mat.define-typography-level(
      56px,
      56px,
      900,
      'Canela Black',
      $letter-spacing: -0.02em
    ),
  $display-2:
    mat.define-typography-level(
      45px,
      48px,
      900,
      'Canela Black',
      $letter-spacing: -0.005em
    ),
  $display-1: mat.define-typography-level(34px, 34px, 900, 'Canela Black'),
  $headline: mat.define-typography-level(32px, 48px, 700, 'Canela Black'),
  $title: mat.define-typography-level(20px, 32px, 500),
  $subheading-2: mat.define-typography-level(16px, 28px, 400),
  $subheading-1: mat.define-typography-level(15px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 24px, 500),
  $body-1: mat.define-typography-level(16px, 24px, 500),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
  $input: mat.define-typography-level(inherit, 1.125, 400)
);

@include mat.legacy-typography-hierarchy($ef-typography);
