@use '@angular/material' as mat;
@import './colors';
@import './media';

// select in mat-form-field
.mat-form-field {

  /* width */
  ::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: mat.get-color-from-palette($mat-ef-revolution-supergrey, 500);
    border-radius: 3px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: mat.get-color-from-palette($mat-ef-revolution-supergrey, 500);
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: mat.get-color-from-palette($mat-ef-revolution-supergrey, 200);
  }

  line-height: 2.25 !important;

  // border around field
  .mat-form-field-flex {
    padding: 0 10px;
    border: none;
    background-color: mat.get-color-from-palette($mat-ef-revolution-grey, 500);
    border-radius: 4px;
    align-items: center;

    input {
      border: none;
    }
  }

  .mat-form-field.mat-focused {
    border: none;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-flex {
    border: none;
  }

  .mat-form-field-prefix {
    color: mat.get-color-from-palette($mat-ef-revolution-grey-two, 500);

    .mat-icon {
      display: block;
    }
  }

  .mat-form-field-suffix {
    color: mat.get-color-from-palette($mat-ef-revolution-grey-two, 500);

    .mat-icon {
      display: block;
    }
  }

  input {
    font-size: 16px;
    background-color: transparent;
    border-radius: 4px;
  }

  // no space on top
  .mat-form-field-infix {
    border-top: none;
  }

  // icon suffix alignment
  // .mat-form-field-suffix .mat-icon {
  //     vertical-align: text-bottom;
  // }

  // hide underline
  .mat-form-field-underline {
    display: none;
  }

  // error padding
  .mat-form-field-subscript-wrapper {
    padding-left: 10px;
    margin-top: 0;
  }

  textarea.mat-input-element {
    padding: 10px 0 0 0;
    margin-bottom: 10px;
  }
}

.mat-form-field-invalid .mat-input-element {
  caret-color: mat.get-color-from-palette($mat-ef-revolution-primary,
      500) !important;
}

.mat-form-field.mat-form-field-disabled {
  .mat-form-field-flex {
    border: none;
    color: lightgray;

    input:hover {
      cursor: not-allowed;
    }
  }
}

.mat-select-disabled:hover .mat-select-trigger:hover {
  cursor: not-allowed;
}

form .mat-error {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  line-height: 1;
  color: mat.get-color-from-palette($mat-ef-revolution-red, 500);
}

.mat-select {
  .mat-select-placeholder {
    color: black;
  }
}

.mat-autocomplete-panel {
  .mat-option {
    min-height: 48px;
    line-height: 24px;
    max-height: unset;
    height: unset;

    &:hover {
      min-height: 48px;
      line-height: 24px;
      max-height: unset;
      height: unset;
    }

    .mat-option-text {
      white-space: normal;
      word-break: break-word;
    }
  }
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  min-height: 48px;
  line-height: 24px !important;
  max-height: unset;
  height: unset !important;

  &:hover {
    min-height: 48px;
    line-height: 24px;
    max-height: unset;
    height: unset;
  }

  .mat-option-text {
    white-space: normal;
    word-break: break-word;
  }
}

.mat-form-field.mat-form-field-has-label {
  .mat-form-field-label {
    width: auto !important;
    padding: 0px 4px;
    background-color: transparent !important;
  }
}

select.mat-input-element {
  margin-bottom: -4px !important;
  top: -10px !important;
}

select {
  margin-top: 0px !important;
  padding-bottom: 4px !important;
}

mat-select {
  box-sizing: border-box;
  padding: 8px;
  border: 1px mat.get-color-from-palette($mat-ef-grey, 400) solid;
  border-radius: 4px;
}

.mat-select-disabled {
  border-color: mat.get-color-from-palette($mat-ef-grey, 100);
}

.mat-form-field.mat-form-field-appearance-legacy {
  .mat-form-field-flex {
    input {
      border: none;
      padding: 0;
      margin: 8px 0px;
    }
  }

  .mat-form-field-infix {
    padding: 0 4px;
    border-top: none;

    mat-select {
      border: none;
      padding: 8px 0px;

      .mat-select-trigger {
        height: 100%;
      }
    }
  }

  .mat-form-field-suffix {
    margin-right: 5px;
    align-self: center;
    display: flex;
    gap: 5px;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-prefix .mat-datepicker-toggle {
    line-height: 1;
    display: block;

    .mat-datepicker-toggle-default-icon {
      width: 20px;
      padding-top: 2px;
    }
  }
}

input[matInput] {
  padding: 8px;
}

// asterisk color
// .mat-focused .mat-form-field-required-marker { color: mat.get-color-from-palette($mat-ef-primary, 500) }
.mat-form-field.white {
  .mat-form-field-flex {
    background-color: white;
  }
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.68125em) scale(0.75) perspective(100px) translateZ(0.00106px) !important;
}

mat-form-field.no-padding {
  .mat-form-field-wrapper {
    padding: 0 !important;
  }
}

mat-form-field {
  &.select-large {
    display: block;

    @media #{$mobile-media,$tablet-media} {
      display: none;
    }
  }

  &.select-small {
    display: none;

    @media #{$mobile-media,$tablet-media} {
      display: block;
    }

    &.mat-form-field-wrapper,
    & .mat-form-field-wrapper {
      @media #{$mobile-media,$tablet-media} {
        margin-bottom: 0 !important;
      }
    }
  }
}


.mobile-login {
  --input-bg: #ffffff33;
  --primary-text-color: white;
  --secondary-text-color: #ffffffcc;
  --bg-color: #023898;
  --error-color: #fb4764;
  .mat-form-field-flex {
    background-color: var(--input-bg) !important;
    // background-color: transparent !important;
    // border-bottom: 1px solid white;
    // border-radius: 0;

    input {
      color: var(--primary-text-color);
    }
  }

  .mat-icon:not(.mat-error, .error) {
    fill: var(--secondary-text-color);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: var(--primary-text-color);
    -webkit-text-fill-color: var(--primary-text-color);
  }

  .slogan,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: var(--secondary-text-color) !important;
  }

  .mat-focused .mat-form-field-required-marker {
    color: var(--secondary-text-color);
  }


  .forgot-password,
  .slogan {
    text-shadow: 1px 1px mat.get-color-from-palette($mat-ef-primary, 500);
  }


  .mat-form-field-invalid .mat-form-field-label,
  mat-error {
    color: var(--error-color) !important;
    text-shadow: 1px 1px mat.get-color-from-palette($mat-ef-primary, 500);
  }

  .submit-button {
    color: var(--bg-color)!important;
    font-weight: 700;
    background-color: var(--primary-text-color);
    border-color: var(--primary-text-color);

    &:disabled {
      opacity: 0.8;
    }
  }
}