@tailwind base;
@tailwind components;
@tailwind utilities;
/** Avatar images */
/** Mat cards for dealrships and salesmen */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
}

.mat-step-header .mat-step-icon {
  background-color: #9e9e9e;
  color: white;
}

.mat-step-header .mat-step-icon:active {
  background-color: #003899;
  color: white;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #003899 !important;
  color: white;
}

.mat-mdc-dialog-actions {
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .mat-mdc-dialog-actions .mat-button-base + .mat-button-base,
  .mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
    margin-left: 0px !important;
  }
  form::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  .second-form-group::-webkit-scrollbar-track {
    background: transparent;
  }
  form::-webkit-scrollbar-track {
    background: transparent;
  }
  .mat-mdc-dialog-title {
    margin: 0 !important;
  }
}
.mat-expansion-panel-content {
  font: inherit;
}

.mat-expansion-panel {
  box-shadow: 0 0 0;
  background: transparent;
  padding: 10px;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  display: flex;
  align-items: center;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-content {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .mat-expansion-panel .mat-expansion-panel-header .mat-content {
    flex-direction: column;
    width: 100%;
  }
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-description {
  margin-top: 14px;
  margin-right: 0;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-description .service-button {
  margin-top: -14px;
  width: 100%;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  width: 100%;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title mat-progress-bar {
  width: 96%;
  --mdc-linear-progress-active-indicator-height: 2px;
  --mdc-linear-progress-track-height: 2px;
}

.mat-button-toggle.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #ffffff;
  background-color: #009aff;
}

.mat-button-toggle .mat-button-toggle-label-content {
  line-height: 38px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
  padding: 0 12px;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #ffffff;
  background-color: #009aff;
}

.mat-mdc-card-header-text {
  flex: 1;
}

.loader {
  display: block !important;
  width: 100% !important;
  background-color: #e1e3e7 !important;
}

.loader.circle {
  height: 100% !important;
}

.mdc-dialog__container .mdc-dialog__surface {
  border-radius: 0;
}

.mdc-dialog__actions {
  position: unset !important;
  justify-content: flex-end !important;
}

.mdc-dialog__title {
  padding: 0 24px 9px 0 !important;
  display: flex !important;
}

.heading-small {
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}

.mat-icon.error {
  color: #f44336;
}

div.favorites-badge {
  margin-left: 5px;
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-content: center;
  justify-content: center;
}
div.favorites-badge p {
  background: #496cf6;
  border-radius: 50%;
  color: white;
  margin: 0px;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  line-height: 100%;
}

.mat-button.mdc-button--unelevated,
.mat-button.mdc-button--unelevated[disabled] {
  border: none;
}

.mat-button.mdc-button--unelevated {
  min-width: 164px;
  min-height: 48px;
  border-width: 2px;
}
.mat-button.mdc-button--unelevated > .mat-button-focus-overlay {
  display: none;
}

.mat-button.mdc-button--unelevated {
  min-width: 164px;
  min-height: 48px;
  border-width: 2px;
}
.mat-button.mdc-button--unelevated > .mat-button-focus-overlay {
  display: none;
}

@media screen and (max-width: 767px), screen and (min-width: 768px) and (max-width: 1123px) {
  .desktop-only {
    display: none !important;
  }
}
@media screen and (min-width: 1124px) and (max-width: 1374px), screen and (min-width: 1375px), screen and (min-width: 1451px) {
  .desktop-only {
    display: block;
  }
}

@media screen and (max-width: 767px), screen and (min-width: 768px) and (max-width: 1123px) {
  .mobile-only {
    display: block;
  }
}
@media screen and (min-width: 1124px) and (max-width: 1374px), screen and (min-width: 1375px), screen and (min-width: 1451px) {
  .mobile-only {
    display: none !important;
  }
}

.mat-mdc-progress-spinner {
  display: inline-block;
  margin-right: 10px;
}

/** Userlike chat height */
@media screen and (max-width: 767px) {
  div[id^=main--userlike-] div[class^=umm-] {
    margin-top: 100%;
  }
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}