@use '@angular/material' as mat;
@import 'colors';
@import 'mixins';
@import 'media';

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
}

.mat-step-header .mat-step-icon {
  background-color: mat.get-color-from-palette(mat.$grey-palette, 500);
  color: white;
}

.mat-step-header .mat-step-icon:active {
  background-color: mat.get-color-from-palette($mat-ef-primary, 500);
  color: white;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: mat.get-color-from-palette($mat-ef-primary, 500) !important;
  color: white;
}

.mat-mdc-dialog-actions {
  justify-content: flex-end;
}

// .mat-button-base+.mat-button-base {
//   margin-left: 20px !important;
// }

@media #{$mobile-media} {

  .mat-mdc-dialog-actions .mat-button-base+.mat-button-base,
  .mat-mdc-dialog-actions .mat-mdc-button-base+.mat-mdc-button-base {
    margin-left: 0px !important;
  }

  form::-webkit-scrollbar {
    display: block;
    width: 5px;
  }

  .second-form-group::-webkit-scrollbar-track {
    background: transparent;
  }

  form::-webkit-scrollbar-track {
    background: transparent;
  }

  .mat-mdc-dialog-title {
    margin: 0 !important;
  }
}

.mat-expansion-panel-content {
  font: inherit;
}

.mat-expansion-panel {
  box-shadow: 0 0 0;
  background: transparent;
  padding: 10px;

  .mat-expansion-panel-header {
    .mat-expansion-panel-header-title {
      display: flex;
      align-items: center;
    }

    .mat-content {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 16px;

      @media #{$mobile-media} {
        flex-direction: column;
        width: 100%;
      }
    }

    .mat-expansion-panel-header-description {
      margin-top: 14px;
      margin-right: 0;

      .service-button {
        margin-top: -14px;
        width: 100%;
      }
    }

    .mat-expansion-panel-header-title {
      width: 100%;

      mat-progress-bar {
        width: 96%;
        --mdc-linear-progress-active-indicator-height: 2px;
        --mdc-linear-progress-track-height: 2px;
      }
    }
  }
}

.mat-button-toggle.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: mat.get-contrast-color-from-palette($mat-ef-accent, 500);
  background-color: mat.get-color-from-palette($mat-ef-accent, 500);
}

.mat-button-toggle {
  .mat-button-toggle-label-content {
    line-height: 38px !important;
  }
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
  padding: 0 12px;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #ffffff;
  background-color: #009aff;
}

.mat-mdc-card-header-text {
  flex: 1;
}

.loader {
  display: block !important;
  width: 100% !important;
  background-color: mat.get-color-from-palette($mat-ef-grey, 300) !important;
}

.loader.circle {
  height: 100% !important;
}

.mdc-dialog__container .mdc-dialog__surface {
  border-radius: 0;
}

.mdc-dialog__actions {
  position: unset !important;
  justify-content: flex-end !important;
}

.mdc-dialog__title {
  padding: 0 24px 9px 0 !important;
  display: flex !important;
}

//mobile navigation

.heading-small {
  text-transform: uppercase;
  color: mat.get-contrast-color-from-palette($mat-ef-grey-dark, 50);
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}

//Mat icon with error
.mat-icon {
  &.error {
    color: mat.get-color-from-palette($mat-ef-error, 500);
  }
}

// Custom badge
div {
  &.favorites-badge {
    margin-left: 5px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    align-content: center;
    justify-content: center;

    p {
      background: mat.get-color-from-palette($mat-ef-indigo-secondary, 500);
      border-radius: 50%;
      color: white;
      margin: 0px;
      height: 24px;
      min-width: 24px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      line-height: 100%;
    }
  }
}

// flat button, turn off all borders
.mat-button.mdc-button--unelevated,
.mat-button.mdc-button--unelevated[disabled] {
  border: none;
}

// turn off overlay on flat buttons
.mat-button.mdc-button--unelevated {
  min-width: 164px;
  min-height: 48px;
  border-width: 2px;

  >.mat-button-focus-overlay {
    display: none;
  }
}

.mat-button.mdc-button--unelevated {
  min-width: 164px;
  min-height: 48px;
  border-width: 2px;

  >.mat-button-focus-overlay {
    display: none;
  }
}

.desktop-only {
  @media #{$mobile-media,$tablet-media} {
    display: none !important;
  }

  @media #{$desktop-media,$desktop-xl-media,$desktop-xxl-media} {
    display: block;
  }
}

.mobile-only {
  @media #{$mobile-media,$tablet-media} {
    display: block;
  }

  @media #{$desktop-media,$desktop-xl-media,$desktop-xxl-media} {
    display: none !important;
  }
}

.mat-mdc-progress-spinner {
  display: inline-block;
  margin-right: 10px;
}

/** Userlike chat height */
div[id^='main--userlike-'] {
  div[class^='umm-'] {
    @media #{$mobile-media} {
      margin-top: 100%;
    }
  }
}