@use '@angular/material' as mat;
@use './colors';
@use './typography';

$primary: mat.define-palette($mat-ef-primary);
$accent: mat.define-palette($mat-ef-accent);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette($mat-ef-warn);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn
    ),
    typography: $ef-typography,
    density: 0
  )
);

// Create the theme for all angular material components
@include mat.all-component-themes($theme);
@include mat.all-legacy-component-themes($theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'EF Sans', Arial, sans-serif;
}

// mat-card styling
mat-card {
  border-radius: 0;
}
.mat-mdc-card {
  padding:16px
}

//dropdown
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  font-size: inherit;
  max-height: 48px;
}

// Material forms

// Energy efficiency classes
.energy-efficiency-class {
  &.de {
    &.a-plus {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-de-a-plus,
        500
      );
    }

    &.a {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-de-a,
        500
      );
    }

    &.b {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-de-b,
        500
      );
    }

    &.c {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-de-c,
        500
      );
    }

    &.d {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-de-d,
        500
      );
    }

    &.e {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-de-e,
        500
      );
    }

    &.f {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-de-f,
        500
      );
    }

    &.g {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-de-g,
        500
      );
    }
  }

  &.ch {
    &.a-plus {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-ch-a-plus,
        500
      );
    }

    &.a {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-ch-a,
        500
      );
    }

    &.b {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-ch-b,
        500
      );
    }

    &.c {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-ch-c,
        500
      );
    }

    &.d {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-ch-d,
        500
      );
    }

    &.e {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-ch-e,
        500
      );
    }

    &.f {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-ch-f,
        500
      );
    }

    &.g {
      background-color: mat.get-color-from-palette(
        $mat-ef-energy-efficiency-ch-g,
        500
      );
    }
  }
}
