/** Avatar images */
@use '@angular/material' as mat;
@mixin avatar-image {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

@mixin avatar-image-cover {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

/** Mat cards for dealrships and salesmen */
@mixin mat-card-dealership {
  background-color: mat.get-color-from-palette($mat-ef-grey, 100) !important;
  display: grid;
  grid-template-columns: 192px minmax(0, 1fr) 96px;
  grid-column-gap: 24px;
  margin-bottom: 16px;
}
