// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@use '@angular/material' as mat;
@import './forms';
@import './buttons_material15';
// @import './buttons';
@import './colors';
@import './media';
@import './typography';
@import './theme';
@import './mixins';
@import './table';
@import './components/star-rating/star-rating';
@import './tailwind-combining';
@import './components/button';
@import './components/calendar';
@import './components/progressBar';
@import './components/toggle';
@import './components/tab';

@import './material15/buttons';
@import './material15/checkbox';

@include mat.core();
@include mat.legacy-core();

body {
  height: unset;
  color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
}

.mat-app-background {
  background-color: #fff !important;
}

/* Here we can manipulate with dialog style.
  * That is global css for all dialogs.
  * If we want change any style we can do that here.
 */

.cdk-overlay-pane {
  &.mat-dialog-cpt {
    width: 708px;
    max-width: 1400px;
    overflow: hidden;
    position: relative;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;

    @media #{$mobile-media} {
      width: 100%;
      padding: 0px;
      max-width: 100% !important;
      max-height: 100% !important;
    }

    .mat-mdc-dialog-content {
      color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
    }

    &.scrollable {
      .mdc-dialog__container .mdc-dialog__surface {
        &>* {
          div.cpt-modal {
            @media #{$mobile-media,$tablet-media} {
              //overflow-y: scroll;
              overflow-x: hidden;

              .mat-mdc-dialog-content {
                overflow: unset;
              }
            }
          }
        }
      }
    }

    &.new-appointment {
      max-width: 1100px !important;
      min-width: 800px;
      width: 65vw;

      @media #{$tablet-media} {
        min-width: 100% !important;
        max-width: 100% !important;
      }

      @media #{$mobile-media} {
        min-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        padding: 0px;
      }

      .mdc-dialog__container .mdc-dialog__surface {
        &>* {
          div.cpt-modal {
            @media #{$mobile-media} {
              .mat-mdc-dialog-content {
                max-height: fit-content;
                flex: 1 1 0;
              }
            }
          }
        }
      }
    }

    &.select-options {
      .mdc-dialog__container .mdc-dialog__surface {
        padding: 24px
      }

      .mat-form-field-wrapper {
        padding-bottom: 12px;
      }

      .mat-mdc-dialog-actions {
        margin: 10px 0 0 0 !important
      }

      max-width: 600px !important;
      min-width: 600px;
      max-height: 600px;

      .cpt-modal {
        max-height: 520px;
      }

      @media #{$mobile-media} {
        max-width: 100vw !important;
        min-width: 100vw;
        width: 100vw;
        max-height: 100vh;
        height: 100vh;

        .cpt-modal {
          max-height: unset;
        }
      }

      .mdc-dialog__container .mdc-dialog__surface {
        &>* {
          div.cpt-modal {
            @media #{$mobile-media} {
              .mat-mdc-dialog-content {
                flex: 1 1 0;
              }
            }
          }
        }
      }

    }

    &.wide {
      width: 66vw;
      height: 75vh;

      @media #{$mobile-media} {
        width: 100%;
        padding: 0px;
      }
    }

    &.find-dealer {
      width: 90vw;
      max-width: 1142px;
      height: 90vh;
      max-height: 830px;

      @media #{$mobile-media} {
        min-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        padding: 0px;

        .mat-mdc-dialog-content {
          max-height: fit-content;
        }
      }
    }

    &.file-preview {
      width: 90vw;
      max-width: 1142px !important;
      height: 90vh;
      max-height: 830px !important;

      @media #{$mobile-media} {
        padding: 0px !important;
        width: 100vw;
        height: 100vh;
      }
    }

    &.dealer-details {
      width: 800px !important;

      .cpt-modal {
        justify-content: normal !important;
      }
    }

    &.no-lr-padding {
      .mdc-dialog__container .mdc-dialog__surface {
        padding: 40px 0px;

        @media #{$mobile-media} {
          padding: 20px;
        }
      }
    }

    &.change-mobile-phone {
      width: 800px !important;
    }

    &.choose-step {
      width: 500px !important;
    }

    .mdc-dialog__container .mdc-dialog__surface {
      flex: 1;
      display: flex;
      border-radius: 0px;
      overflow: hidden;
      padding: 40px;

      // Here is padding for dialog on mobile devices
      @media #{$mobile-media} {
        padding: 24px;
        max-width: 100%;
      }

      &>* {
        flex: 1;
        display: flex;

        div.cpt-modal {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .mat-divider {
            margin: 12px 0px;
          }

          .mat-mdc-dialog-title {
            color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
            font-size: 34px;
            line-height: 41px;
            font-family: 'Canela Black', Arial, sans-serif;
            max-width: calc(100% - 30px);

            @media #{$mobile-media} {
              font-size: 34px;
              line-height: 41px;
            }
          }

          .mat-mdc-dialog-content {
            flex: 1;
            margin: 0px;
            padding: 24px 0px;
            max-width: 100%;

            @media #{$mobile-media} {
              overflow-y: auto;
            }

            form {
              @media #{$mobile-media} {
                max-width: calc(100vw - 48px);
              }
            }
          }

          .mat-mdc-dialog-actions {
            padding: 0px;
            //margin: 10px 30px;

            @media #{$mobile-media} {
              //padding: 20px;
              margin: 10px 0px;
              gap: 10px;
            }
          }

          &[hidden] {
            display: none;
          }

          .close-button {
            position: absolute;
            right: 0px;
            top: 0px;
          }
        }
      }

      .dialog-content-p-0 {
        .mat-mdc-dialog-content {
          padding: 24px 0 4px 0 !important;
        }
      }

      .mat-mdc-dialog-actions-pb-20 {
        padding-bottom: 24px !important;
      }

      .mat-divider-p-0 {
        .mat-divider {
          padding: 0 !important;
        }
      }
    }
  }
}

.mat-dialog-mobile-menu {
  .mdc-dialog__container .mdc-dialog__surface {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.leasing-title-warning {
  mat-icon {
    width: 24px;
    height: 24px;
    margin: 0px 5px;
  }

  &.ok-green {
    mat-icon {
      color: mat.get-color-from-palette($mat-ef-green-secondary, 500);
    }
  }

  &.warn-orange {
    mat-icon {
      color: mat.get-color-from-palette($mat-ef-yellow-secondary, 500);
    }
  }

  &.warn-red {
    mat-icon {
      color: mat.get-color-from-palette($mat-ef-red-secondary, 500);
    }
  }
}

table {
  width: 100%;
  border-bottom: none;

  .mat-sort-header-arrow[style] {

    // Hide default arrow stem
    .mat-sort-header-stem {
      display: none;
    }

    .mat-sort-header-indicator {
      opacity: 1;
      color: black;
      font-weight: bold;

      // Hide default arrow as its composed of left, right and middle
      .mat-sort-header-pointer-left,
      .mat-sort-header-pointer-right,
      .mat-sort-header-pointer-middle {
        display: none;
      }
    }
  }

  // My custom ascending arrow
  [aria-sort='ascending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          content: '\25B4';
          top: -0em;
          position: absolute;
          color: #004e87;
          font-size: 20px;
          line-height: 10px;
        }
      }
    }
  }

  // My custom descending arrow
  [aria-sort='descending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          content: '\25BE';
          top: -15px;
          position: absolute;
          color: #004e87;
          font-size: 20px;
        }
      }
    }
  }

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 0 !important;
  }

  .mat-mdc-header-cell {
    font-size: 14px;
    font-weight: 700;
    // color: mat.get-color-from-palette($mat-ef-primary,500);
    // text-transform: uppercase;
  }
}

// GLIDER *******************************************
.glide {
  position: unset;
}

.glide__slides {
  min-width: fit-content !important;
}

.glide__arrows {
  .arrowTemplate {
    position: absolute;
    display: block;
    z-index: 2;
    line-height: 1;

    width: 60px;
    height: 60px;
    border: 2px solid mat.get-color-from-palette($mat-ef-revolution-primary, 500);
    border-radius: 50%;

    .mat-icon {
      fill: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
      color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
    }

    &.white {
      border-color: white;

      .mat-icon {
        fill: white;
        color: white;
      }
    }

    &.sideArrows {
      left: unset;
      bottom: 50%;
      transform: translateY(50%);
    }

    &.bottomArrows {
      bottom: 0;
      left: 50%;

      &.leftArrow {
        left: calc(50% - 75px);
      }

      &.rightArrow {
        left: calc(50% + 15px);
      }
    }
  }
}

.glide__bullets {
  bottom: 80px;
  z-index: 99;
  cursor: default;

  .glide__bullet {
    background-color: mat.get-color-from-palette($mat-ef-revolution-supergrey,
        900);

    &--active {
      background-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
          500);
    }

    margin:0.25em
  }
}

.documents {
  .glide__arrows {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100%;
    position: absolute;
    left: 45%;
    bottom: 50%;
    top: 50%;
    transform: translateX(-50%);
    padding: 0 40px;

    .arrowTemplate {
      width: 54px;
      height: 54px;
      background-color: #ffffff;
      border: none;

      .mat-icon {
        fill: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
        color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
      }

      &.white {
        border-color: white;

        .mat-icon {
          fill: white;
          color: white;
        }
      }
    }

    .left {
      margin-right: 25px;
    }
  }

  .glide__bullets {
    display: none !important;
  }
}

.RevolutionVehicleListComponent {
  .image-wrapper img {
    max-width: calc(100% - 62px);
    max-height: calc(100% - 62px);
  }

  &.vehicles-view,
  &.tracking {
    .glide__slides {
      padding-top: 10px;
    }

    .showBullets .glide__bullets {
      bottom: -30px;
      display: flex !important;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 40px;
    }
  }

  .fitGlider {

    .glide__track,
    .glide__slides {
      display: flex !important;
      justify-content: center !important;
      transform: none !important;
    }
  }
}

.RevolutionArticlesComponent {
  .fitGlider {

    .glide__track,
    .glide__slides {
      display: flex !important;
      justify-content: center !important;
      transform: none !important;
    }
  }
}

.RevolutionServicesComponent {
  .fitGlider {

    .glide__track,
    .glide__slides {
      display: flex !important;
      justify-content: center !important;
      transform: none !important;
    }
  }
}

.RevolutionMarketplaceFavoritesComponent {
  .arrow-bg {
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 25% 100%, 0 50%);
  }

  .glide__slides {
    padding-top: 10px;
  }

  .showBullets .glide__bullets {
    bottom: -30px;
    display: flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
}

// GLIDER *******************************************

// mat-expansion-panel ---------------------------------------
.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-expansion-indicator {
  padding: 24px;
}

.mat-expansion-indicator-less-padding .mat-expansion-indicator {
  padding: 12px 24px;
}

.mat-expansion-indicator-less-left-padding .mat-expansion-indicator {
  padding: 24px 8px;
  margin-right: 16px;
}

.mat-expansion-indicator::after {
  border-color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
}

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0);
}

.mat-expansion-panel-header.cpt-panel {
  .mat-expansion-indicator {
    padding: 0 !important;
  }
}

.DealerHoursComponent {
  .mat-expansion-panel {
    padding: 0;
  }

  .mat-expansion-panel-header {
    &.mat-expanded {
      height: fit-content;
    }

    .mat-content {
      padding: 0;
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.noPaddingPanelBody .mat-expansion-panel-body {
  padding: 0;
}

.panelBodyGreyBorder {
  .mat-expansion-panel-body {
    border: 1px solid #f3f4f6;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .mat-expansion-panel-header.mat-expanded {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

// mat-expansion-panel ---------------------------------------

// paginator ---------------------------------------
mat-paginator[ef-paginator] {
  .mat-button {
    margin: 0 1px !important;
    padding: 0 5px !important;
    width: min-content !important;
    min-width: 30px !important;
    height: 36px !important;
    min-height: 36px !important;
  }

  .mat-icon-button {
    line-height: 100% !important;
    padding: 0 !important;
  }

  .mat-mdc-paginator-page-size,
  .mat-mdc-paginator-range-label {
    display: none !important;
  }

  .mat-mdc-paginator-container {
    justify-content: center !important;
  }

  .selectedPage {
    border-bottom: 3px solid !important;
    border-color: mat.get-color-from-palette($mat-ef-primary, 500) !important;
  }
}

// paginator ---------------------------------------

// scrollbar *******************************************
.ef-scrollbar {

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: mat.get-color-from-palette($mat-ef-revolution-supergrey, 500);
    border-radius: 3px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: mat.get-color-from-palette($mat-ef-revolution-supergrey, 500);
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: mat.get-color-from-palette($mat-ef-revolution-supergrey, 200);
  }
}

// scrollbar *******************************************



.mat-mdc-tab-link.active {
  border-bottom: 2px solid mat.get-color-from-palette($mat-ef-revolution-secondary, 500);
  opacity: 1;
}

// radio *******************************************
.mdc-radio__outer-circle {
  border-color: mat.get-color-from-palette($mat-ef-revolution-primary,
      500) !important;
  border-width: 1px !important;
}

.remove-vehicle-form {
  @media #{$mobile-media,$tablet-media} {
    .mat-radio-label-content {
      white-space: normal;
      line-height: normal;
    }
  }
}

.mat-radio-label-content {
  color: mat.get-color-from-palette($mat-ef-revolution-primary, 500) !important;
}

.white-space-normal .mdc-label {
  white-space: normal !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
      500) !important;
  border-width: 2px !important;
}

.mat-mdc-radio-button .mdc-radio__inner-circle {
  background-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
      500) !important;
}

.mat-radio-disabled {
  opacity: 0.6;
}

.mat-mdc-radio-button .mat-radio-ripple {
  display: none !important;
}

#employeeWrapper {
  .mat-mdc-radio-button {
    &.salesman {
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;

      &.selected {
        .mdc-form-field {
          background-color: rgba(123, 169, 255, 0.3) !important;
        }
      }

      .mdc-form-field {
        width: 100%;
        border-radius: 0.5rem;
        color: mat.get-color-from-palette($mat-ef-primary, 500);
        background-color: mat.get-color-from-palette($mat-ef-grey,
            100) !important;

        .mdc-radio {
          margin-right: 16px;
        }

        .mdc-radio__outer-circle {
          border-color: mat.get-color-from-palette($mat-ef-primary,
              500) !important;
        }

        .mdc-radio__inner-circle {
          background-color: mat.get-color-from-palette($mat-ef-primary, 500);
        }

        .mdc-label {
          cursor: pointer;
          display: grid;
          grid-template-columns: 96px minmax(0, 1fr) 96px;
          grid-column-gap: 24px;
          width: 100%;
        }
      }
    }
  }
}

.appointment-form {
  .mat-mdc-radio-button {
    width: 100%;
    display: inline-block;

    .mdc-radio {
      margin-right: 16px;
    }

    .mdc-form-field {
      width: 100%;
    }
    .mdc-label {
      cursor: pointer;
      width: 100%;
    }
  }
}

// radio *******************************************

.ef-snackbar {
  font-family: 'EF Sans', Arial, sans-serif !important;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0px rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

  &.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #{mat.get-color-from-palette($mat-ef-revolution-grey, 200)};
    --mdc-snackbar-container-shape: 0.375rem;
    --mdc-snackbar-supporting-text-size: 16px;
    --mdc-snackbar-supporting-text-weight: 600;

    border-radius: var(--mdc-snackbar-container-color);

    .mdc-snackbar__label {
      --mdc-snackbar-supporting-text-color: #{mat.get-color-from-palette($mat-ef-revolution-primary, 500)};
    }
  }

  .mdc-snackbar__surface {
    padding: 0;
  }
}

.ef-snackbar.mat-mdc-snack-bar-container {
  color: white;

  .container {
    display: flex;
    justify-content: space-between;

    .message {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }

    .action {
      margin-left: 20px;

      .mat-button {
        min-width: min-content;
        padding: 0 5px;
      }
    }
  }
}

.image-preview {
  background-size: cover;
  background-position: center;

  img {
    @include avatar-image;
  }
}

.cptMenuContainer {
  height: 40px;
  width: fit-content;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

mat-paginator[ef-paginator] {
  .selectedPage {
    border-bottom: 2px solid !important;
    border-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
        500) !important;
  }

  button {
    background-color: transparent !important;
  }

  .mat-mdc-paginator-icon {
    fill: mat.get-color-from-palette($mat-ef-revolution-secondary,
        500) !important;
  }

  .mat-button-disabled .mat-mdc-paginator-icon {
    fill: mat.get-color-from-palette($mat-ef-revolution-supergrey,
        500) !important;
  }
}

// Revolution form - no autofill color
form {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    // box-shadow: 0 0 0 30px mat.get-color-from-palette($mat-ef-revolution-grey, 500) inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}

// hide password
input.hidePassword {
  font-family: text-security-disc !important;
  /* Use -webkit-text-security if the browser supports it */
  -webkit-text-security: disc !important;
}

.mat-dialog-lightbox {
  max-width: 60%;

  .mdc-dialog__container .mdc-dialog__surface {
    padding: 0;
    border: 1px solid #e0e0e0;
    height: auto;
  }
}

.mat-mdc-paginator-page-size-select {
  width: 86px !important;
}

.noYPadding .mat-mdc-list-item-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.a-link {
  a {
    color: mat.get-color-from-palette($mat-ef-revolution-secondary,
        500) !important;
    text-decoration: underline !important;
  }
}

table {
  width: 100%;
}

tr.example-detail-row {
  height: 0;
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: whitesmoke;
}

tr.example-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

.example-element-row td {
  border-bottom-width: 0;
}

.example-element-detail {
  overflow: hidden;
}

.break-spaces {
  white-space: break-spaces;
}

.break-words {
  overflow-wrap: break-word;
}

.vehicle-expand {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

.first-form-group {
  mat-mdc-radio-button {
    width: 100%;

    .mat-radio-label-content.mat-radio-label-before {
      width: 90%;
    }
  }
}

// Stepper

.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-width: 4px !important;
}

.mat-horizontal-stepper-header::before,
.mat-stepper-horizontal-line {
  border-top-width: 4px !important;
}

.mat-horizontal-stepper-header {
  height: 72px !important;
  padding: 26px 0 !important;

  .mat-stepper-horizontal-line {
    border-top-width: 4px !important;
    top: 10px !important;
  }

  .mat-step-label {
    position: fixed;
    width: 200px !important;
    white-space: pre-wrap;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 10px !important;
    color: mat.get-color-from-palette($mat-ef-revolution-primary,
        500) !important;
    margin-top: 20px !important;
  }

  &::before {
    width: 0;
  }
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
  .mat-step-label {
    padding: 0;
    font-size: 12px;
    overflow: initial;
  }
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
  align-items: flex-start;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.mat-horizontal-stepper-header:focus {
  pointer-events: none !important;
  background-color: transparent;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header.mat-focused {
  background-color: transparent;
}

@media #{$mobile-media} {
  .mat-horizontal-stepper-content {
    outline: 0;
  }

  .mat-mdc-dialog-content {
    overflow-x: hidden !important;

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
      margin-bottom: -40px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
      margin-bottom: 34px;
      background-color: transparent;

      .mat-step-label {
        display: none;
      }
    }
  }
}

.mat-stepper-horizontal {
  display: flex;
  max-width: 100%;
  max-height: 100%;

  .mat-horizontal-stepper-wrapper,
  .mat-horizontal-content-container,
  .mat-horizontal-stepper-content {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .mat-horizontal-content-container,
  .mat-horizontal-stepper-content {
    display: flex;
    flex-direction: column;
  }
}

.mat-horizontal-content-container {
  padding: 0 8px 8px 8px;
}

.no-padding .mat-horizontal-content-container {
  padding: 0;
}

.mat-stepper-horizontal-line {
  border-top-width: 1px !important;
  border-color: mat.get-color-from-palette($mat-ef-revolution-primary,
      500) !important;
}

.mat-step-header[aria-labelledby='done'] .mat-step-label,
.mat-step-label-selected {
  color: mat.get-color-from-palette($mat-ef-revolution-secondary,
      500) !important;
}

.mat-step-icon {
  background-color: transparent !important; //#4e596c !important;
  margin-top: 1px;
  width: 18px !important;
  height: 18px !important;

  .mat-step-icon-content {
    border: 1px solid mat.get-color-from-palette($mat-ef-revolution-primary, 500) !important;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    font-size: 10px;
    color: mat.get-color-from-palette($mat-ef-revolution-primary,
        500) !important;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: mat.get-color-from-palette($mat-ef-revolution-primary,
          500) !important;
      font-size: 8px;
    }

    mat-icon {
      font-size: 14px;
      height: 14px;
      width: 14px;
    }
  }
}

.mat-step-icon-selected .mat-step-icon-content,
.mat-step-icon-state-done .mat-step-icon-content,
.mat-step-header[aria-labelledby='done'] .mat-step-icon-content {
  border-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
      500) !important;
  background-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
      500) !important;
  color: white !important;

  span {
    color: white;
  }
}

@for $i from 0 through 10 {
  .selectedIndex#{$i} {
    .mat-stepper-horizontal-line:nth-child(#{$i * 2}) {
      border-top-width: 1px !important;
    }
  }
}

.cpt-tracking {
  .mat-mdc-progress-bar {
    height: 2px !important;
    --mdc-linear-progress-active-indicator-height: 2px;
    --mdc-linear-progress-track-height: 2px;
  }

  .mdc-linear-progress__buffer-bar {
    background-color: mat.get-color-from-palette($mat-ef-revolution-grey,
        900) !important;
  }

  .mdc-linear-progress__bar-inner {
    border-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
        500) !important;
  }

  .track-trace-active {
    .mat-icon-wrapper {
      background-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
          500) !important;

      mat-icon {
        fill: white !important;
      }
    }
  }
}

// Stepper

// Pdf
.document-preview {
  ngx-extended-pdf-viewer {
    .zoom {
      max-height: 360px;
      min-height: 360px;
      height: 360px !important;
    }

    .pdfViewer .page {
      margin: 0;
    }
  }
}

.file-preview {
  ngx-extended-pdf-viewer {
    .zoom {
      max-height: 80vh;
      min-height: 360px;
      height: 700px !important;
    }

    #scaleSelect {
      height: 24px;
      padding-bottom: 0 !important;
    }
  }
}

// Pdf

// ef-spinner

#ef-spinner {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #{var(--color)};
    margin-right: 10px;
  }
}

.EfSpinnerDialogComponent {
  .mdc-dialog__container .mdc-dialog__surface {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
}

// ef-spinner

// social-icons

img.social-icons {
  height: 30px;
  filter: invert(95%) sepia(0%) saturate(4486%) hue-rotate(359deg) brightness(112%) contrast(101%);
}

//

#RevolutionTyreMoleculeComponent {
  .text-fill {
    fill: mat.get-color-from-palette($mat-ef-revolution-grey-two, 500);
  }

  .st0 {
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 5.6374;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: none;
    stroke: #cccccc;
    stroke-width: 4.51;
    stroke-miterlimit: 10;
  }

  .st2 {
    fill: none;
    stroke: #cccccc;
    stroke-width: 4.51;
    stroke-miterlimit: 10;
    stroke-dasharray: 19.0693, 19.0693;
  }

  .st3 {
    fill: none;
    stroke: #cccccc;
    stroke-width: 4.51;
    stroke-miterlimit: 10;
    stroke-dasharray: 19.9531, 19.9531;
  }

  .st4 {
    fill: none;
    stroke: #cccccc;
    stroke-width: 4.51;
    stroke-miterlimit: 10;
    stroke-dasharray: 20.1344, 20.1344;
  }

  .st5 {
    fill: #d0021b;
  }

  .st6 {
    fill: #d9d9d9;
  }

  .st7 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 3.3706;
    stroke-miterlimit: 10;
  }

  .st8 {
    fill: #f56923;
  }

  .st9 {
    fill: #64be00;
  }

  .st10 {
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 4.51;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st11 {
    fill: none;
    stroke: #d9d9d9;
    stroke-width: 6;
    stroke-miterlimit: 10;
  }

  .st12 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 6;
    stroke-miterlimit: 10;
  }

  .st13 {
    fill: #494949;
    stroke: #494949;
    stroke-miterlimit: 10;
  }
}

#VehicleEnergyComponent {
  .energy-efficiency-class {
    &.a-plus {
      width: 68px;
      clip-path: polygon(80% 0, 100% 50%, 80% 100%, 0 100%, 0 0);
    }

    &.a {
      width: 88px;
      clip-path: polygon(85% 0, 100% 50%, 85% 100%, 0 100%, 0 0);
    }

    &.b {
      width: 108px;
      clip-path: polygon(88% 0, 100% 50%, 88% 100%, 0 100%, 0 0);
    }

    &.c {
      width: 128px;
      clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
    }

    &.d {
      width: 148px;
      clip-path: polygon(92% 0, 100% 50%, 92% 100%, 0 100%, 0 0);
    }

    &.e {
      width: 168px;
      clip-path: polygon(94% 0, 100% 50%, 94% 100%, 0 100%, 0 0);
    }

    &.f {
      width: 188px;
      clip-path: polygon(94% 0, 100% 50%, 94% 100%, 0 100%, 0 0);
    }

    &.g {
      width: 208px;
      clip-path: polygon(94% 0, 100% 50%, 94% 100%, 0 100%, 0 0);
    }
  }

  .arrow {
    clip-path: polygon(0% 50%, 30% 0%, 100% 0%, 100% 100%, 30% 100%);
    color: white;
  }
}

#RevolutionUserPersonalisationComponent {
  div.energy-efficiency-class {
    &.a-plus {
      width: 88px;
    }

    &.a {
      width: 108px;
    }

    &.b {
      width: 128px;
    }

    &.c {
      width: 148px;
    }

    &.d {
      width: 188px;
    }

    &.e {
      width: 208px;
    }

    &.f {
      width: 228px;
    }

    &.g {
      width: 248px;
    }
  }
}

.header-brands {
  filter: invert(100%) sepia(100%) saturate(7313%) hue-rotate(187deg) brightness(109%) contrast(100%);
}

.word-break_break-word {
  word-break: break-word;
}

.mat-tab-group_hide-header .mat-mdc-tab-header { // TODO: EFDAID-627 - Check .mat-tab-group_hide-header class and replace it with a corresponding mdc class
  display: none !important;
}

.ef-tab-nav-bar {
  .mat-mdc-tab-link {
    opacity: 0.6 !important;

    &.active {
      border-bottom: none !important;
      opacity: 1 !important;
    }
  }

  .mdc-tab-indicator__content--underline {
    background-color: transparent !important;
    --mdc-tab-indicator-active-indicator-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)};
    --mat-tab-header-active-focus-indicator-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)};
    --mat-tab-header-active-hover-indicator-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)};
  }

  .mdc-tab__text-label {
    --mat-tab-header-active-label-text-color: #000000de;
    --mat-tab-header-active-focus-label-text-color: #000000de;
    --mat-tab-header-active-hover-label-text-color: #000000de;
  }
}

.flexboxContainer::after {
  content: '';
  flex-grow: 999;
}

.break-anywhere {
  overflow-wrap: anywhere !important;
}

.animation-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.inputNumberNoArrows {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.aboutView {

  .footerLinks,
  .footerImpressum {
    color: mat.get-color-from-palette($mat-ef-revolution-primary,
        500) !important;
  }

  .footer {
    background-color: transparent !important;
  }

  .mat-icon {
    fill: mat.get-color-from-palette($mat-ef-revolution-primary,
        500) !important;
  }

  .footerDivider {
    #parting-line {
      background-color: mat.get-color-from-palette($mat-ef-revolution-primary,
          500) !important;
      opacity: 0.5 !important;
      margin: 0 24px !important;
      max-width: calc(100% - 48px) !important;
    }
  }

  .footerDE {

    .footer-v2-link,
    .footerEF,
    .footerLinks,
    .footerLegalNotice {
      color: mat.get-color-from-palette($mat-ef-revolution-primary,
          500) !important;
    }

    .footerLinksContainer {
      padding-top: 32px !important;
    }

    .social-icons {
      filter: brightness(0) saturate(100%) invert(8%) sepia(65%) saturate(2374%) hue-rotate(212deg) brightness(90%) contrast(112%) !important;
    }

    mat-divider {
      background-color: mat.get-color-from-palette($mat-ef-revolution-primary,
          500) !important;
      opacity: 0.5 !important;
    }
  }
}

.box-shadow-full {
  box-shadow: 0 20px 80px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
}

.AppointmentsPopupChooseStepComponent {
  .mdc-label {
    white-space: normal !important;
  }
}

// mat-list
.mat-mdc-list {
  .mdc-list-item {
    display: flex;
    flex-direction: column;
    padding: 0;

    .mdc-list-item__content {
      display: block;
      padding: 16px;
      width: 100%;
    }
  }
}
