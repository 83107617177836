@charset "UTF-8";

/*theme modifiers
====================================================*/

.rating {
  &.theme-kununu {
    $kununu-color-white: #fff !default;
    $kununu-color-lightgray: #f8f8f8 !default;
    $kununu-color-gray: #e9ecec !default;
    $kununu-color-blue: #2286dc !default;
    $kununu-color-darkblue: #2f3940 !default;
    $kununu-color-green: #99c613 !default;

    flex-direction: column;
    width: 78px;

    .label-value,
    .star-container {
      width: 100%;
    }

    .label-value {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 6px 6px 0 0;
      height: 50px;
      border: 1px solid $kununu-color-gray;
      border-bottom: 0px;
      font-size: 18px;
      font-weight: bold;
      color: $kununu-color-darkblue;
      letter-spacing: -1px;

      background-color: $kununu-color-lightgray;
    }
    .star-container {
      border-radius: 0 0 6px 6px;
      padding: 2px 0 4px 0;
      margin-left: 0px;
      margin-right: 0px;

      justify-content: center;
      background-color: $kununu-color-green;

      border: 1px solid $kununu-color-green;
      border-bottom: 0px;

      .star {
        height: 11px;
        width: 11px;
        @extend .custom-icon;

        i {
          font-size: 11px;
          color: $kununu-color-white;
          text-align: center;
        }
      }
    }
  }

  &.theme-google-places {
    $google_places-color-default: #e1e1e1 !default;
    $google_places-color-red: #e7711b !default;

    .label-value {
      color: $google_places-color-red;
      font-family: arial, sans-serif;
      font-size: 13px;
      line-height: 15px;
    }

    .star-container {
      width: 65px;
      margin-left: 2px;

      .star {
        i {
          font-size: 17px;
          color: $google_places-color-red !important;
          &.star-empty {
            opacity: 1 !important;
            color: $google_places-color-default !important;
            &:before {
              content: $default-star-character-filled;
            }
          }
          &.star-half {
            width: 7px;
            overflow: hidden;
            &:before {
              content: $default-star-character-half;
            }
          }
          &.star-filled:before {
            content: $default-star-character-filled;
          }
        }
      }
    }
  }

  &.theme-classic {
  }

  &.theme-material {
  }

  &.theme-rolling-stars {
    .star-container {
      .star {
        transition: transform 1s;
        transform: rotate(0deg);
      }
    }

    @for $i from $minRatingValue through $maxNumOfStars {
      &.value-#{$i} {
        .star-container {
          .star:nth-child(-n + #{$i}) {
            transition: transform 1s;
            transform: rotate(360deg);
          }
        }
      }
    }

    &.value-0.half {
      .star:nth-child(1) {
        transition: transform 1s;
        transform: rotate(360deg);
      }
    }

    @for $i from $minRatingValue through $maxNumOfStars {
      &.value-#{$i}.half {
        .star-container {
          @if ($i < $maxNumOfStars) {
            .star:nth-child(#{$i + 1}) {
              transition: transform 1s;
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}
