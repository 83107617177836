@import '../colors';

.mat-calendar-table-header {
  color: mat.get-color-from-palette($mat-ef-primary, 500);
  tr th {
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
    text-align: center;
  }
  tr .mat-calendar-table-header-divider:after {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.mat-calendar-body {
  tr td {
    font-size: 16px;
  }
}

.mat-toolbar {
  position: relative;
  z-index: 1;

  img {
    height: 42px;
    margin-right: 16px;
  }

  h1 {
    color: mat.get-color-from-palette($mat-ef-primary, 500);
  }
}
