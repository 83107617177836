@import './media';

.customer-portal_responsive-table {
  .mobile-label {
    display: none !important;
  }
  @media #{$mobile-media} {
    .mat-mdc-header-row {
      display: none;
    }

    .mobile-label {
      display: flex !important;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      min-width: fit-content;
    }

    .mat-mdc-row {
      flex-direction: column;
      align-items: start;
      padding: 0px;
      height: fit-content;
      display: flex;
      gap: 12px;

      .mat-mdc-cell {
        width: 100%;
        display: flex;
        padding-left: 0;
        gap: 12px;
      }
    }
  }
}
