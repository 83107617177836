@use '@angular/material' as mat;
@import '../colors';

.ef-toggle.mat-mdc-slide-toggle .mdc-form-field {
  flex-direction: column-reverse;
}

.mdc-switch.mdc-switch--selected .mdc-switch__icon, .mdc-switch.mdc-switch--unselected .mdc-switch__icon {
  display: none;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-handle-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)};
  --mdc-switch-selected-pressed-handle-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)};
  --mdc-switch-selected-focus-handle-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)};
  --mdc-switch-selected-hover-handle-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 500)};
  --mdc-switch-unselected-handle-color: #{mat.get-color-from-palette($mat-ef-grey, 500)};
  --mdc-switch-unselected-pressed-handle-color: #{mat.get-color-from-palette($mat-ef-grey, 500)};
  --mdc-switch-unselected-focus-handle-color: #{mat.get-color-from-palette($mat-ef-grey, 500)};
  --mdc-switch-unselected-hover-handle-color: #{mat.get-color-from-palette($mat-ef-grey, 500)};
  --mdc-switch-selected-focus-track-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 200)};
  --mdc-switch-selected-hover-track-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 200)};
  --mdc-switch-selected-pressed-track-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 200)};
  --mdc-switch-selected-track-color: #{mat.get-color-from-palette($mat-ef-revolution-secondary, 200)};
  --mdc-switch-unselected-focus-track-color: #{mat.get-color-from-palette($mat-ef-grey, 200)};
  --mdc-switch-unselected-hover-track-color: #{mat.get-color-from-palette($mat-ef-grey, 200)};
  --mdc-switch-unselected-pressed-track-color: #{mat.get-color-from-palette($mat-ef-grey, 200)};
  --mdc-switch-unselected-track-color: #{mat.get-color-from-palette($mat-ef-grey, 200)};
}
.mat-mdc-slide-toggle.mat-accent .mdc-label {
  font-family: 'EF Sans', Arial, sans-serif;
  font-size: 14px;
  color: #{mat.get-color-from-palette($mat-ef-revolution-primary, 500)};
}
