@use '@angular/material' as mat;
@import '../colors';



.mat-mdc-checkbox {
  &.mat-mdc-checkbox-disabled {
    opacity: 0.7;
  }

  .mdc-checkbox {
    padding: 0 !important;
    margin: 0 !important;
    padding-right: 4px !important;
  }

  label {
    white-space: normal;
    color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
    font-weight: 400;
  }

  .mdc-checkbox .mdc-checkbox__background {
    top: 0 !important;
    left: 0 !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    border-color: mat.get-color-from-palette($mat-ef-revolution-primary,
        500) !important;
  }

  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    display: none;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
        500) !important;
    border-color: mat.get-color-from-palette($mat-ef-revolution-secondary,
        500) !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:disabled:checked~.mdc-checkbox__background {
    border-color: transparent !important;
  }



  .mat-mdc-checkbox-touch-target {
    top: 0 !important;
    height: 18px !important;
    left: 0 !important;
    width: 18px !important;
    transform: none !important;
  }

  .mdc-checkbox__native-control {
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 18px !important;
    height: 18px !important;
  }
}