@use '@angular/material' as mat;
@import '../colors';
@import '../media';


// defaulth height
.mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
    height: 48px;
}

// dont show grey background on hover
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
}

.mat-mdc-button .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 100%;

    span {
        white-space: nowrap;
    }
}

.mat-mdc-button.revolution {
    border-radius: 4px !important;
    max-height: 50px !important;
}

.mat-mdc-button.revolution.icon-button {
    min-width: fit-content;
    .mat-icon {
        margin-right: 0;
        margin-left: 0;
    }
}

// button flat----------------------------------------------------------
.mat-mdc-button.mdc-button--unelevated {
    min-width: 164px;
    min-height: 48px;
    border-width: 2px;
}

.mat-mdc-button.mdc-button--unelevated.mat-secondary.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-secondary, 500);
}

.mat-mdc-button.mdc-button--unelevated.mat-primary.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
}

.mat-mdc-button.mdc-button--unelevated.mat-warn.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-red, 500);
}

.mat-mdc-button.mdc-button--unelevated.mat-primary.revolution,
.mat-mdc-button.mdc-button--unelevated.mat-secondary.revolution,
.mat-mdc-button.mdc-button--unelevated.mat-warn.revolution {
    color: white;

    &:disabled {
        border-color: mat.get-color-from-palette($mat-ef-grey, 500);
        background-color: mat.get-color-from-palette($mat-ef-grey, 500);
    }

    .mat-mdc-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// button flat hover
.mat-mdc-button.mdc-button--unelevated.mat-secondary.revolution:not([disabled]):hover {
    background-color: mat.get-color-from-palette($mat-ef-revolution-secondary, 500);

    >.mat-mdc-button-persistent-ripple .mdc-button__ripple {
        display: none;
    }
}

.mat-mdc-button.mdc-button--unelevated.mat-primary.revolution:not([disabled]):hover {
    background-color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);

    >.mat-mdc-button-persistent-ripple .mdc-button__ripple {
        display: none;
    }
}

// button flat----------------------------------------------------------


// button raised -------------------------------------------------------
.mat-mdc-button.mdc-button--raised.mat-secondary.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-secondary, 500) !important
}

.mat-mdc-button.mdc-button--raised.mat-primary.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-primary, 500) !important
}

.mat-mdc-button.mdc-button--raised.mat-warn.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-red, 500);
}

.mat-mdc-button.mdc-button--raised.mat-secondary.revolution,
.mat-mdc-button.mdc-button--raised.mat-primary.revolution,
.mat-mdc-button.mdc-button--raised.mat-warn.revolution {
    color: white;

    &:disabled {
        border-color: mat.get-color-from-palette($mat-ef-grey, 500);
        background-color: mat.get-color-from-palette($mat-ef-grey, 500);
    }
}

// button raised -------------------------------------------------------

// mini fab
.mat-mini-fab.mat-secondary.revolution,
.mat-mini-fab.mat-primary.revolution,
.mat-mini-fab.mat-warn.revolution {
    color: white;

    &:disabled {
        border-color: mat.get-color-from-palette($mat-ef-grey, 500);
        background-color: mat.get-color-from-palette($mat-ef-grey, 500);
    }
}

.mat-mini-fab.mat-secondary.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-secondary, 500);

}

.mat-mini-fab.mat-primary.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
}

.mat-mini-fab.mat-warn.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-red, 500);
}

// mini fab

// button stroked (now outlined)-----------------------------------------
.mat-mdc-button.mat-mdc-outlined-button {
    border-width: 2px;
    min-width: 164px;
    min-height: 48px;

    >.mat-button-focus-overlay {
        display: none;
    }
}

.mat-mdc-button.mat-mdc-outlined-button.mat-secondary.revolution,
.mat-mdc-button.mat-mdc-outlined-button.mat-primary.revolution,
.mat-mdc-button.mat-mdc-outlined-button.mat-warn.revolution {

    &:disabled {
        border-color: mat.get-color-from-palette($mat-ef-grey, 500);
        color: mat.get-color-from-palette($mat-ef-grey, 500);
    }
}

.mat-mdc-button.mat-mdc-outlined-button.mat-primary.revolution {
    border-color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
    color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
}

.mat-mdc-button.mat-mdc-outlined-button.mat-secondary.revolution {
    border-color: mat.get-color-from-palette($mat-ef-revolution-secondary, 500);
    color: mat.get-color-from-palette($mat-ef-revolution-secondary, 500);
}

.mat-mdc-button.mat-mdc-outlined-button.mat-warn.revolution {
    border-color: mat.get-color-from-palette($mat-ef-revolution-red, 500);
    color: mat.get-color-from-palette($mat-ef-revolution-red, 500);
}

// stroked button hover color when not disabled
.mat-mdc-button.mat-mdc-outlined-button.mat-primary.revolution:not([disabled]):hover {
    border-color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
    color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);
}

.mat-mdc-button.mat-mdc-outlined-button.revolution {
    >.mat-mdc-button-persistent-ripple .mdc-button__ripple {
        display: none;
    }
}

// button stroked ------------------------------------------------------
.mat-mdc-button.mat-basic.revolution {
    background-color: mat.get-color-from-palette($mat-ef-revolution-grey-two, 100);
    color: mat.get-color-from-palette($mat-ef-revolution-primary, 500);

    >.mat-mdc-button-persistent-ripple .mdc-button__ripple {
        display: none;
    }
}

.mat-mdc-button.revolution.medium-button {
    min-width: 124px;
    min-height: 32px;
    font-size: 14px;
    font-weight: bold;

    .mat-icon {
        max-width: 14px;
        min-width: 14px;
    }

    @media #{$mobile-media,$tablet-media} {
        min-width: 80px;
        min-height: 24px;
        line-height: 24px;
        font-size: 12px;
        font-weight: bold;

        .mat-icon {
            max-width: 12px;
            min-width: 12px;
        }

    }

}

.driver-licence {
    display: grid;
    grid-template-columns: auto auto;


    @media #{"screen and (min-width: 1025px) and (max-width: 1279px)"} {
        grid-template-columns: auto;
    }

}

.mat-mdc-button.revolution.vehicle-btn {
    width: 132px;
    height: 52px;

    @media #{"screen and (min-width: 769px) and (max-width: 1023px)"} {
        width: 110px;
    }

    @media #{"screen and (min-width: 320px) and (max-width: 450px)"} {
        width: 110px;
    }

}

.mat-mdc-button.revolution.small-button {
    min-width: 80px;
    min-height: 26px;
    line-height: 26px;
    height:26px;
    font-size: 12px;
    font-weight: bold;

    .mat-icon {
        max-width: 14px;
        min-width: 14px;
        font-weight: bold;
    }
}

.button-spinner {
    .mat-mdc-progress-spinner {
        margin-right: 10px;
    }

    .mat-mdc-button-wrapper {
        display: inline-flex;
        align-items: center;
    }
}