.mat-mdc-progress-bar {
  &.warn-orange {
    .mdc-linear-progress__bar-inner {
      border-color: mat.get-color-from-palette(
        $mat-ef-yellow-secondary,
        500
      );
    }
  }

  &.warn-red {
    .mdc-linear-progress__bar-inner {
      border-color: mat.get-color-from-palette($mat-ef-red-secondary, 500);
    }
  }

  &.ok-green {
    .mdc-linear-progress__bar-inner{
      border-color: mat.get-color-from-palette(
        $mat-ef-green-secondary,
        500
      );
    }
  }
}
